<template>
  <WeContainer card="">
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-cube"></i>
        <span>Üye İptal & İade Raporları</span>
      </h5>
    </WeCard>
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:show-icon="false"
      header-class="bg-white text-dark"
      header-text-class="text-dark h6"
      v-bind:show-on-load="true"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Tarih"
                placeholder="Tarih"
                v-bind:date-range="true"
                v-model="filter.dates"
                selected-shortcut="thisMonth"
              />
            </div>
            <!-- Created At -->
          </div>
          <!-- Left -->

          <!-- Right -->
          <div class="col-12 col-lg-6">
            <!-- Member -->
            <div class="d-block mb-3">
              <WeRowInput
                form="searchSelect"
                size="sm"
                name="customer"
                label="Üye"
                select-label="full_name"
                placeholder="Üye"
                v-bind:clearable="true"
                v-model="filter.member"
              />
            </div>
            <!-- Member -->
          </div>
          <!-- Right -->
        </div>
      </div>
    </WeTableSearch>
    <WeCard>
      <div class="position-relative" style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <div v-else v-bind:id="printConfig.id">
        <span v-print="printConfig" ref="printButton"> </span>
        <div class="text-center print">
          <h5 class="font-weight-bold">{{ getFileName }}</h5>
        </div>
        <WeTable
          v-bind:index="false"
          v-bind:columns="getColumns"
          v-bind:actions="actions"
          v-bind:data="list"
          v-bind:exportables="exportables"
          v-on:on-export="onExport"
          v-on:on-action="onAction"
          v-bind:paginatable="paginatable"
          v-bind:pageDetails="pageDetails"
          v-bind:limitable="limitable"
          v-bind:searchable="searchable"
          v-bind:show-table-total="true"
          v-bind:table-total="tableTotalPrice"
          v-bind:hide-table-total-print="true"
          total-column="total_price"
        />
        <div class="col-12 text-right" v-if="!paginatable && tableTotalPrice">
          <span class="font-weight-bold"
            >Toplam Tutar : {{ tableTotalPrice | moneyFormat }}
          </span>
        </div>
      </div>
    </WeCard>

    <MemberReturnDetail
      v-if="showModal && memberDetail"
      v-bind:loading="modalLoading"
      v-bind:detail="memberDetail"
      v-on:close="onCloseDetail"
    />
  </WeContainer>
</template>
<script>
import { mapState, mapActions } from "vuex";
const MemberReturnDetail = () => import("./views/MemberReturnDetail/Index.vue");

export default {
  name: "Returns",
  components: {
    MemberReturnDetail,
  },
  data() {
    return {
      loading: true,
      modalLoading: true,
      showModal: false,
      paginatable: true,
      pageDetails: true,
      limitable: true,
      searchable: true,
      tableTotalPrice: 0,
      filter: {
        dates: {
          start: null,
          end: null,
        },
        member: null,
      },
      list: [],
      columns: [
        { name: "current_code", th: "Hesap Kodu", type: "string" },
        { name: "full_name", th: "Ad Soyad", type: "string" },
        { name: "email", th: "E-Posta Adresi", type: "string" },
        { name: "cart_amount", th: "İptal & İade Miktarı", type: "integer" },
        {
          name: "total_price",
          th: "Toplam İptal & İade Tutarı",
          type: "currency",
        },
      ],
      actions: [
        {
          icon: "fas fa-list",
          class: "btn-outline-indigo",
          action: "detail",
          tooltip: "İptal & İade Sipariş Listesi",
        },
      ],
      exportables: ["excel", "pdf", "print"],
      printConfig: {
        id: "Returns",
        popTitle: "Üye İptal & İade Raporları",
      },
      memberDetail: null,
    };
  },
  methods: {
    ...mapActions("reports", ["makeSearch", "show"]),
    ...mapActions("tableExport", ["exportData"]),
    getFilter(copyDates = true) {
      let copy = helper.clone(this.filter);
      copy.dates = [];

      if (
        copyDates &&
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        Object.entries(this.filter.dates).forEach(([key, value]) => {
          if (value) {
            copy.dates.push(value);
          }
        });
      } else {
        delete copy.dates;
      }

      return copy;
    },
    onSearch() {
      this.loading = true;
      this.makeSearch({
        routeKey: "memberReturn",
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.list = result.data.items;
          this.tableTotalPrice = result.data.table_total;
          this.loading = false;
        },
      });
    },
    onExport(data) {
      if (this.list && this.list.length) {
        if (data.name !== "print") {
          let fileName = "Üye İptal & İade Raporları";

          if (
            this.filter.dates &&
            this.filter.dates.start &&
            this.filter.dates.end
          ) {
            let startDate = new Date(
              this.filter.dates.start
            ).toLocaleDateString("tr-TR");
            let endDate = new Date(this.filter.dates.end).toLocaleDateString(
              "tr-TR"
            );

            fileName = startDate + " - " + endDate + " - " + fileName;
          }

          this.exportData({
            route: "report/members/returns/export",
            exportable: data,
            fileName: fileName,
            filter: {
              ...this.getFilter(),
              type: data.name,
            },
          });
        } else {
          this.setPrintableSettings(true);
          this.$refs.printButton.click();

          setTimeout(() => {
            this.setPrintableSettings(false);
          }, 100);
        }
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veya Yazdırılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    setPrintableSettings(print = false) {
      this.paginatable = !print;
      this.pageDetails = !print;
      this.limitable = !print;
      this.searchable = !print;

      this.list.sort();
    },
    onAction(data) {
      if (data.key == "detail") {
        this.showDetail(data.row);
      }
    },
    onFilterClear() {
      this.filter = {
        dates: {
          start: null,
          end: null,
        },
        category: null,
      };
      this.onSearch();
    },
    showDetail(row) {
      this.memberDetail = {};
      this.memberDetail.id = row.id;
      this.memberDetail.title = `${row.full_name} (${row.current_code})`;
      this.modalLoading = true;
      this.showModal = true;

      this.show({
        routeKey: "memberReturnOrder",
        id: row.id,
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.memberDetail.orders = result.data.items;
          this.memberDetail.tableTotalPrice = result.data.table_total;
          this.memberDetail.filter = this.getFilter();
        },
        onFinish: () => {
          this.modalLoading = false;
        },
      });
    },
    onCloseDetail() {
      this.showModal = false;
      this.memberDetail = null;
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    getFileName() {
      const defaultName = "Üye İptal & İade Raporları";
      let result = "";

      if (
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        let startDate = this.filter.dates.start;
        let endDate = this.filter.dates.end;

        startDate = new Date(startDate).toLocaleDateString("tr-TR");
        endDate = new Date(endDate).toLocaleDateString("tr-TR");

        result = `${startDate} - ${endDate} ${defaultName}`;
      } else {
        result = defaultName;
      }

      return result;
    },
    getColumns() {
      let columns = this.columns;
      let customColumns = [];

      if (customColumns.length) {
        for (let i = customColumns.length - 1; i >= 0; i--) {
          const custom = customColumns[i];
          columns.splice(2, 0, custom);
        }
      }
      return columns;
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>